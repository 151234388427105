import { QueryClient } from "react-query";

function onError(e) {
  alert(`Error ${e.response.status}\n${JSON.stringify(e.response.data)}`)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      staleTime: 1000,
      retry: false,
      refetchOnWindowFocus: false,
      onError
    },
    mutations: {
      onError
    }
  },
});
