import { Auth } from "aws-amplify";
import axios from "axios";

axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.request.use(
  (requestConfig) =>
    Auth.currentSession()
      .then((session) => {
        requestConfig.withCredentials = true;
        requestConfig.headers["Authorization"] = session.idToken.jwtToken;
        return requestConfig;
      })
      .catch((e) => {
        console.warn(e);
        return Promise.reject(e);
      }),
  (e) => console.warn(new Error(e)),
);
