import React, { createContext, useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CButton } from "@coreui/react-pro";

export const AuthContext = createContext({});

export default function Authenticator({ children }) {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [authState, setAuthState] = useState();

  const loginUser = useCallback(
    (session, state) => {
      setUser(session.idToken.decodePayload());
      setToken(session.idToken.jwtToken);
      setAuthState(state);
    },
    [setUser, setAuthState],
  );

  const logoutUser = useCallback(() => {
    Auth.signOut();
    setUser(undefined);
    setAuthState(undefined);
    setToken(undefined);
  }, [setUser, setAuthState]);

  useEffect(() => {
    Auth.currentSession().then((data) => {
      loginUser(data, "signedin");
    });
  }, [loginUser]);

  if ("signedin" === authState) {
    return (
      <AuthContext.Provider value={{ user, token, authState, logoutUser }}>
        {children}
      </AuthContext.Provider>
    );
  } else {
    return <CButton onClick={() => Auth.federatedSignIn()}>Sign in</CButton>;
  }
}
