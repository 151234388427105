export default {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_rQ9XhWeoZ',
    userPoolWebClientId: '2jlubd1pv411n8c052clouc061',
    mandatorySignIn: true,
    oauth: {
      domain: 'identity.widewail.com',
      scope: ['openid'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECTURI,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECTURI,
      responseType: 'code',
    },
  },
}
